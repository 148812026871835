import { ApiServiceV1 } from '@services/api';

export const AccessJournalApiService = {
  async getAccessJournal({
    suffix, action = null, operatorId = null,
    filterId = null, pageNum = 1, pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/access`, {
      params: {
        action, operatorId, filterId, pageNum, pageSize,
      },
    });

    return data || [];
  },

  async getAccessJournalItemData({ suffix, id }) {
    const { data } = await ApiServiceV1.get(`${suffix}/access/${id}/data`);

    return data || {};
  },
};
