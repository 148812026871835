<template>
  <v-layout column>
    <vbt-search
      v-model="searchData"
      :items="searchFieldsConfig"
      @reset="onResetSearch"
      @search="onSearch"
    />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="noContent"
      :go-back-btn="goBack"
      title="Information"
    >
      <vbt-table
        :headers="headers"
        :items="accessJournal"
      >
        <template #processDate="{ item: { processDate: _dateForFormatting } }">
          {{ formatDisplayDate(_dateForFormatting) }}
        </template>

        <template #filterId="{ item }">
          <router-link
            :to="{
              name: 'ruleEdit',
              query: { suffix },
              params: { id: item.filterId },
            }"
            target="_blank"
          >
            {{ item.filterId }}
          </router-link>
        </template>

        <template #actions="{ item }">
          <v-btn
            v-if="item.dataExists"
            color="info"
            small
            icon
            @click="getAccessJournalItemData(item)"
          >
            <v-icon>
              remove_red_eye
            </v-icon>
          </v-btn>
        </template>
      </vbt-table>

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />

      <vbt-dialog
        v-model="itemData.dialog"
        :title="itemData.title"
        hide-submit-btn
      >
        <vbt-code
          :value="itemData.code"
          max-height="537px"
          readonly
        />
      </vbt-dialog>
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { useDataLoading, usePagination, useSearch } from '@mixins/factories';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { AppLocationsNamespace, EnumsNamespace } from '@store/types';

import { OperatorsApiService } from '@services/api';

import { AccessJournalApiService } from '../_services/accessJournal.api.service';

const dataLoadingOptions = { getterName: 'getAccessJournal', contentName: 'accessJournal' };
const paginationOptions = { contentName: 'accessJournal' };
const searchOptions = {
  initialSearchDataFields: [
    'action', 'operatorId', 'filterId',
  ],
  customTypes: {
    operatorId: 'number',
  },
};

const headers = Object.freeze([
  { text: 'Operator', value: 'operator' },
  { text: 'Action', value: 'action' },
  { text: 'Process Date', value: 'processDate' },
  { text: 'Rule ID', value: 'filterId' },
  { text: '', value: 'actions', width: '10px' },
]);

export default {
  name: 'DsbAccessJournal',

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      accessJournal: [],
      operatorsOptions: [],
      itemData: { code: '', title: '', dialog: false },

      headers,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapGetters(EnumsNamespace, ['accessJournalTypes']),

    searchFieldsConfig() {
      return [
        {
          value: 'filterId',
          label: 'Rule ID',
          type: 'text',
          flex: 'sm4',
        },
        {
          value: 'action',
          label: 'Action',
          items: this.accessJournalTypes,
          type: 'select',
          flex: 'sm4',
        },

        {
          value: 'operatorId',
          label: 'Operator',
          items: this.operatorsOptions,
          type: 'combobox',
          flex: 'sm4',
        },
      ];
    },
  },

  watch: {
    suffix: {
      handler(suffix) {
        if (suffix) {
          this.getOperators(suffix);
        }
      },
      immediate: true,
    },
  },

  methods: {
    getAccessJournal(params) {
      return this.wrapToLoadingFn({
        req: AccessJournalApiService.getAccessJournal.bind({}, params),
        loadingFlagName: 'loading',
        onSuccess: (r) => {
          this.accessJournal = r;
        },
      });
    },

    getOperators(suffix) {
      this.wrapToLoadingFn({
        req: OperatorsApiService.getOperators.bind({}, { suffix }),
        onSuccess: (r) => {
          this.operatorsOptions = r.map(({ email: text, id: value }) => ({ text, value }));
        },
      });
    },

    getAccessJournalItemData(item) {
      this.wrapToLoadingFn({
        req: AccessJournalApiService.getAccessJournalItemData.bind({}, {
          suffix: this.suffix,
          id: item.id,
        }),
        fullscreenLoadingSpinner: true,
        onSuccess: (r) => this.showItemDataViewer(r.data || '', item),
      });
    },

    showItemDataViewer(codeStr, { operator, action }) {
      try {
        this.itemData.code = JSON.stringify(JSON.parse(codeStr), null, '\t');
        this.itemData.title = `${operator} - ${action}`;
        this.itemData.dialog = true;
      } catch {
        this.$VBlackerTheme.notification.warning('Invalid data');
      }
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
